import React from "react";
import Layout from "../components/common/layout";
import Main from "../components/common/main";
import SEO from "../components/common/seo";

const intro = (
  <>
    <div className="vb-150" />
    <div className="container line-container d-flex">
      <div className="col px-0">
        <div className="row">
          <div className="col-lg-10">
            <h1 className="display-2  text-white">
                Like what you see? 
                <span>Come say hello 🙋‍</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div className="pt-100" />
  </>
);

const body = (
  <section className="section bg-secondary">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="row row-grid">
            <div className="col-lg-12">
                <div className="row row-grid align-items-center">
                    <div className="col-md-6 order-md-1 mt-5">
                        <div className="card bg-gradient-secondary shadow">
                          <div className="card-body p-lg-5">
                            <form action="https://formspree.io/ashish.gd@gmail.com" method="POST">
                              <h4 className="mb-1">Want to work with us?</h4>
                              <p className="mt-0">We would love to hear more about it.</p>
                              <div className="form-group mt-5">
                                <div className="input-group input-group-alternative">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fa fa-id-badge fa-lg"></i></span>
                                  </div>
                                  <input className="form-control" name="name" placeholder="Your name" type="text" />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="input-group input-group-alternative">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                                  </div>
                                  <input className="form-control" name="email" placeholder="Email address" type="email" />
                                </div>
                              </div>
                              <div className="form-group mb-4">
                                <textarea className="form-control form-control-alternative" name="message" rows="8" cols="80" placeholder="Type a message..."></textarea>
                              </div>
                              <div>
                                <button type="submit" className="btn btn-default btn-round btn-block btn-lg">Send Message</button>
                              </div>
                            </form>
                          </div>
                        </div>
                    </div>
                    <div className="col-md-6 order-md-2 pl-md-5 mt-5">
                      <p className="lead">If the adjacent form seems tedious, just write or call us at:</p>
                      <ul className="list-unstyled">
                        <li>
                          <p className="lead"><i className="fa fa-envelope pr-2"></i><a href="mailto:contact@polyglots.io">contact@polyglots.io</a></p>
                        </li>
                        <li>
                          <p className="lead"><i className="fa fa-phone fa-lg pr-2"></i><a href="tel:+91 986-006-9212">+91 986-006-9212</a></p>
                        </li>
                        <li>
                          <p className="lead">Or even better, just drop in to share a thought at:</p>
                        </li>
                      </ul>
                      {/* <hr/>
                      <h6><i className="fa fa-map-marker fa-2x pr-2" style={{color: '#525f7f'}} ariaHidden="true"></i>Or even better, just drop in to share a thought at:</h6>
                      <ul className="list-unstyled">
                        <li>
                          <p className="lead"><i className="fa fa-map-marker pr-2"></i>5170, Marvel Fuego, Magarpatta Road, Hadapsar, Pune - 411028</p>
                        </li>
                      </ul> */}
                      <div className="card bg-gradient-secondary shadow mt-5">
                        <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15132.727846393316!2d73.9327635!3d18.5206776!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x41de20032f83590!2sPolyglots!5e0!3m2!1sen!2sin!4v1567709631643!5m2!1sen!2sin" width="455" height="300" frameBorder="1" style={{border:1}} allowFullScreen=""></iframe>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Contact = props => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Main lineColor="line-default">{intro}</Main>
      {body}
    </Layout>
  )
}

Contact.propTypes = {}

export default Contact

import { Link } from "gatsby";
import React from "react";

const Footer = props => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row row-grid align-items-center my-lg">
          <div className="col-lg-6">
            <h3 className="text-primary font-weight-light mb-2">
              Thank you for visiting us!
            </h3>
            <h4 className="mb-0 font-weight-light">
              You can reach us on "<a href="mailto:contact@polyglots.io">contact@polyglots.io</a>".
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 col-footer">
            <div className="pr-lg-5">
              <h1 className="h6 text-uppercase font-weight-700 mb-3">
                <strong>Polyglots</strong>{" "}
              </h1>
              <p>Pune, India</p>
            </div>
          </div>
          <div className="col-6 col-md col-footer">
            <h6 className="heading">Development</h6>
            <ul className="list-unstyled text-small">
              <li>
                <Link to="/services/development">Web</Link>
              </li>
              <li>
                <Link to="/services/development">Mobile</Link>
              </li>
              <li>
                <Link to="/services/development">Cloud</Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md col-footer">
            <h5 className="heading">Consulting</h5>
            <ul className="list-unstyled text-small">
              <li>
                <Link to="/services/consulting">Re-engineering</Link>
              </li>
              <li>
                <Link to="/services/consulting">DevOps</Link>
              </li>
              <li>
                <Link to="/services/consulting">Process</Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md col-footer">
            <h5 className="heading">Technologies</h5>
            <ul className="list-unstyled text-small">
              <li>
                <Link to="/services/development">Angular / React</Link>
              </li>
              <li>
                <Link to="/services/development">Node.js</Link>
              </li>
              <li>
                <Link to="/services/development">Spring Cloud</Link>
              </li>
              <li>
                <Link to="/services/development">Docker</Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md col-footer">
            <h5 className="heading">About</h5>
            <ul className="list-unstyled text-small">
              <li>
                <Link to="/company">Company</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <a href="https://medium.com/polyglots-blog" target="_blank" rel="noopener noreferrer">Blog</a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row align-items-center justify-content-md-between">
          <div className="copyright">
            <a href="https://www.creative-tim.com/product/argon-design-system" target="_blank" rel="noopener noreferrer">Argon</a> by
            <a href="https://www.creative-tim.com" target="_blank" rel="noopener noreferrer"> Creative Tim</a>. Converted to 
            <a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer"> GatsbyJS</a> by
            <a href="https://polyglots.io/" target="_blank" rel="noopener noreferrer"> Polyglots</a>.
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {}

export default Footer

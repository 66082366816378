import React from "react"
import PropTypes from "prop-types"

const Main = props => {
  const lineColor = props.lineColor || 'line-default';
  const lineStyle = props.lineStyle || 'line-style-1';
  const lineSkew = (props.lineSkew) ? 'line-skew' : '';
  const lineClasses = `line ${lineStyle} ${lineColor} ${lineSkew}`
  return (
    <main>
      <header className="position-relative">
        <section className="section-lined my-0">
          <div className={lineClasses}>
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          {props.children}
        </section>
      </header>
    </main>
  )
}

Main.propTypes = {
    children: PropTypes.node.isRequired,
    lineColor: PropTypes.string,
    lineStyle: PropTypes.string,
    lineSkew: PropTypes.bool,
}

export default Main

import { Link } from "gatsby";
import React, { Component } from "react";

export default class Header extends Component {

  componentDidMount() {
    window.$('[data-toggle="tooltip"]').tooltip();
    window.$(".navbar-main .collapse").on("hide.bs.collapse", function() {
      var $this = window.$(this)
      $this.addClass("collapsing-out")
    })

    window.$(".navbar-main .collapse").on("hidden.bs.collapse", function() {
      var $this = window.$(this)
      $this.removeClass("collapsing-out")
    })

    window.$(".navbar-main .dropdown").on("hide.bs.dropdown", function() {
      var $this = window.$(this).find(".dropdown-menu")

      $this.addClass("close")

      setTimeout(function() {
        $this.removeClass("close")
      }, 200)
    })
  }

  render() {
    return (
      <header className="header-global">
        <nav id="navbar-main" className="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light">
          <div className="container">
            <Link className="navbar-brand mr-lg-5" to="/">
              Polyglots
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse collapse" id="navbar_global">
              <div className="navbar-collapse-header">
                <div className="row">
                  <div className="col-6 collapse-brand">
                    <Link to="/">
                      {/* <img src="../assets/img/brand/blue.png" alt="Polyglots"></img> */}
                      Polyglots
                    </Link>
                  </div>
                  <div className="col-6 collapse-close">
                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
                      <span></span>
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
              <ul className="navbar-nav navbar-nav-hover align-items-lg-center">
                <li className="nav-item">
                  <Link to="/" className="nav-link" role="button">
                    <i className="ni ni-shop d-lg-none"></i>
                    <span className="nav-link-inner--text">Home</span>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a href="#" className="nav-link" data-toggle="dropdown" role="button">
                    <i className="ni ni-app d-lg-none"></i>
                    <span className="nav-link-inner--text">Services</span>
                  </a>
                  <div className="dropdown-menu">
                    <Link to="/services/development" className="dropdown-item">Development</Link>
                    <Link to="/services/consulting" className="dropdown-item">Consulting</Link>
                    <Link to="/services/training" className="dropdown-item">Training</Link>
                  </div>
                </li>
                <li className="nav-item">
                  <Link to="/company" className="nav-link" role="button">
                    <i className="ni ni-badge d-lg-none"></i>
                    <span className="nav-link-inner--text">Company</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/work" className="nav-link" role="button">
                    <i className="ni ni-collection d-lg-none"></i>
                    <span className="nav-link-inner--text">Work</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <a href="https://medium.com/polyglots-blog" target="_blank" rel="noopener noreferrer" className="nav-link" role="button">
                    <i className="ni ni-single-copy-04 d-lg-none"></i>
                    <span className="nav-link-inner--text">Blog</span>
                  </a>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link" role="button">
                    <i className="ni ni-send d-lg-none"></i>
                    <span className="nav-link-inner--text">Contact</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }

}

/* const Header = ({ siteTitle }) => (
  <header className="header-global">
    <nav id="navbar-main" className="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light">
      <div className="container">
        <Link className="navbar-brand mr-lg-5" to="/">
          Polyglots
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse" id="navbar_global">
          <div className="navbar-collapse-header">
            <div className="row">
              <div className="col-6 collapse-brand">
                <Link to="/">
                  <img src="../assets/img/brand/blue.png"></img>
                </Link>
              </div>
              <div className="col-6 collapse-close">
                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
          <ul className="navbar-nav navbar-nav-hover align-items-lg-center">
            <li className="nav-item">
              <Link to="/" className="nav-link" role="button">
                <i className="ni ni-ui-04 d-lg-none"></i>
                <span className="nav-link-inner--text">Home</span>
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a href="#" className="nav-link" data-toggle="dropdown" role="button">
                <i className="ni ni-ui-04 d-lg-none"></i>
                <span className="nav-link-inner--text">Services</span>
              </a>
              <div className="dropdown-menu">
                <Link to="/services/development" className="dropdown-item">Development</Link>
                <Link to="/services/consulting" className="dropdown-item">Consulting</Link>
                <Link to="/services/training" className="dropdown-item">Training</Link>
              </div>
            </li>
            <li className="nav-item">
              <Link to="/company" className="nav-link" role="button">
                <i className="ni ni-ui-04 d-lg-none"></i>
                <span className="nav-link-inner--text">Company</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/work" className="nav-link" role="button">
                <i className="ni ni-collection d-lg-none"></i>
                <span className="nav-link-inner--text">Work</span>
              </Link>
            </li>
            <li className="nav-item">
              <a href="https://medium.com/polyglots-blog" target="_blank" rel="noopener noreferrer" className="nav-link" role="button">
                <i className="ni ni-ui-04 d-lg-none"></i>
                <span className="nav-link-inner--text">Blog</span>
              </a>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link" role="button">
                <i className="ni ni-ui-04 d-lg-none"></i>
                <span className="nav-link-inner--text">Contact</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header */
